export const MAIN_CONTENT_ID = 'main-content';
export const REQUEST_BLOGS_PENDING = 'REQUEST_BLOGS_PENDING';
export const REQUEST_BLOGS_SUCCESS = 'REQUEST_BLOGS_SUCCESS';
export const REQUEST_BLOGS_FAILED = 'REQUEST_BLOGS_FAILED';
export const REQUEST_PROJECTS_PENDING = 'REQUEST_PROJECTS_PENDING';
export const REQUEST_PROJECTS_SUCCESS = 'REQUEST_PROJECTS_SUCCESS';
export const REQUEST_PROJECTS_FAILED = 'REQUEST_PROJECTS_FAILED';
export const REQUEST_ARTICLE_PENDING = 'REQUEST_ARTICLE_PENDING';
export const REQUEST_ARTICLE_SUCCESS = 'REQUEST_ARTICLE_SUCCESS';
export const REQUEST_ARTICLE_FAILED = 'REQUEST_ARTICLE_FAILED';
export const SEND_EMAIL_PENDING = 'SEND_EMAIL_PENDING';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';

export const REQUEST_TYPE_BLOG = 'REQUEST_TYPE_BLOG';
export const REQUEST_TYPE_PROJECT = 'REQUEST_TYPE_PROJECT';
export const DATE_FORMAT = 'd MMM yyyy';