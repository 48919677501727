import React from 'react';
import CardBlog from './CardBlog';
import CuteLoader from '../Utilities/CuteLoader';
import ErrorGeneric from '../Utilities/ErrorGeneric';

const CardListBlog = ({ cardBlogs, searchFilters, message, subMessage, errorMessage }) => {
  var cardComponent = <CuteLoader />
  if (errorMessage) {
    cardComponent = <ErrorGeneric message="Error loading blog posts! How embarrassing!" />;
    console.log(errorMessage);
  }
  if (cardBlogs.length > 0) {
    cardComponent = cardBlogs.map((article, i) => {
        return <CardBlog key={i} path={article.Path} title={article.Title} date={article.CreatedDate}
                        imagelink={article.HeaderImg} description={article.Description}/>
    });
  }
  return (
    <div className="mb1 pa3">
        <h2 className="f2 fw2 black-90 mb3 mt0">
          <div>{message}</div>
        </h2>
        <h3 className="f4 fw2 black-50 mt0">
          {subMessage}
        </h3>
        <div className="center flex flex-wrap justify-around">
          { cardComponent }
        </div>
    </div>
  );
}

export default CardListBlog