import React from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import Error404 from '../Utilities/Error404';
import CuteLoader from '../Utilities/CuteLoader';
import { requestArticle } from '../../actions';


const mapStateToProps = state => {
  return {
      articleIsPending: state.requestArticle.isPending,
      articleData: state.requestArticle.data,
      articleError: state.requestArticle.error,
  }
}


// What props should i listen to that are actions which need
// to be dispatched? dispatch sends actions to the reducer
const mapDispatchToProps = (dispatch) => {
  return {
      onRequestArticle: (pageComponent) => dispatch(requestArticle(pageComponent))
  }
}


class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
      error: null
    };
  }

  addComponent = async pageName => {
    // console.log(`Loading Page ${pageName} ...`);

    import(`../Pages/${pageName}.js`)
      .then(component =>
        this.setState({
          components: this.state.components.concat(component.default)
        })
      )
      .catch(error => {
        console.error(`"${pageName}" does not exist: ${error}`);
        this.setState({
          error: 404
        });
      });
  };

  async componentDidMount() {
    // console.log("params: ", this.props.match.params);
    if(!this.props.match.params) {
      console.error("404?? LOL");
    } else {
      const pageComponent = this.props.match.params.pageName.toLowerCase();
      this.props.onRequestArticle(pageComponent);
      await this.addComponent(pageComponent);
    }
  }

  render() {
    const components = this.state.components;
    const { articleIsPending, articleData, articleError } = this.props;
    if (this.state.error === 404 || articleError !== '') {
      return <div><Error404 /></div>;
    }
    else if (components.length === 0 || articleIsPending || !articleData) {
      return <div><CuteLoader /></div>;
    }
    const componentsElements = components.map(Component => (
      <Component key={shortid.generate()} articleIsPending={articleIsPending}
        articleData={articleData} articleError={articleError}/>
    ));
    // console.log(articleData)
    return (
      <div>
        {componentsElements}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);