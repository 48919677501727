import React from 'react';
import { Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns'
import {
  DATE_FORMAT
} from '../../constants.js';
import './cards.css'

class CardProject extends React.Component {
  render() {
    const images = require.context('../../images', true);
    let img = images('./' + this.props.imagelink);
    return (
      <div className="hover--bordered big-project-card br4 ma2">
        <div className='bg-light-gray br4 shadow-1  center'>
          <Link to={`/page/${this.props.path}`} style={{ textDecoration: 'none', color:'inherit' }}>
            <div className="image-wrapper">
              <picture className="big-project-card-picture">
                <img loading="lazy" sizes="(min-width: 1122px) 296px,(min-width: 866px) 218px,(max-width: 610px) 202px,202px"
                      src={img} alt={this.props.title} className="big-blog-card-image"/>
              </picture>
            </div>
            <div className="pa2">
                <h6>{format(parseISO(this.props.date), DATE_FORMAT)}</h6>
                <h5 className="as-hover-green">{this.props.title}</h5>
                <p>{this.props.description}</p>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default CardProject;
