import React, { Component } from 'react';
import { Tweenable } from 'shifty';

var tweenable = new Tweenable();

class Point {
  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }
}

const springMin = 0.3;

const configs = [
  { points: 3, springMax: 3.0 },
  { points: 5, springMax: 4.2 },
  { points: 6, springMax: 3.0 },
  { points: 7, springMax: 2.6 },
  { points: 8, springMax: 2.4 },
]

// TODO:
// - Have a target and current color variable. tween towards the new color
// - Have a target and current Springiness. Tween towarsd the new springness
// - Have a target and current rotation speed. Tween toward the rotation speed
// - have a function for setting these targets?
class Spiro extends Component {

  componentDidMount() {
    // console.log('isplaying: ', tweenable.isPlaying());
    // console.log('this.canv: ', this.canv);
    this.canv = document.getElementById("canv");
    this.ctx = document.getElementById("canv").getContext('2d');
    this.nPicker = document.getElementById("n");
    this.sPicker = document.getElementById("s");
    this.stPicker = document.getElementById("st");
    this.con = document.getElementById("con");
    this.vw = window.innerWidth;
    this.vh = window.innerHeight;
    this.step = 2;
    this.points = [];
    this.size = null;
    this.c = new Point(this.canv.width / 2, this.canv.width / 2);
    this.additionFactor = 1;
    this.configIdx = 2;
    this.numberOfPoints = configs[this.configIdx].points;
    if (tweenable.isPlaying()) {
      tweenable.pause();
      tweenable.stop();
    }
    this.initAnimation();
    // console.log('home mounted');
  }

  initAnimation = () => {
    this.animateSpiro(springMin, configs[this.configIdx].springMax);
  }

  componentWillUnmount() {
    tweenable.pause();
    tweenable.stop();
    // console.log('stopping animation');
  }


  animateSpiro = (springnessStart, springnessEnd) => {
    tweenable.setConfig({
      from: { springness: springnessStart },
      to: { springness: springnessEnd },
      duration: 6000,
      easing: 'easeInOutQuad',
      step: state => {
        // console.log('step');
        this.springness = state.springness;
        this.resize();
      }
    })
    tweenable.tween().then(() => {
      this.ctx = null;
      this.ctx = document.getElementById("canv").getContext('2d');
      // console.log('finished');
      tweenable.pause();
      tweenable.stop();
      tweenable = new Tweenable();
      if (this.springness > springMin) {
        // The spirograph should basically look like a circle at this point
        if (this.configIdx === 0 || this.configIdx === configs.length - 1) {
          this.additionFactor *= -1;
        }
        this.configIdx += this.additionFactor;
        this.animateSpiro(configs[this.configIdx].springMax, springMin);
        this.numberOfPoints = configs[this.configIdx].points;
      } else {
        this.animateSpiro(springMin, configs[this.configIdx].springMax);
      }
    });
  }

  resize = () => {
    this.vw = window.innerWidth;
    this.vh = window.innerHeight;

    this.size = Math.min(this.vw, this.vh);
    if (this.canv.width !== this.size ||
      this.canv.height !== this.size) {
      this.canv.height = this.canv.width = this.size;
      this.c.x = this.size / 2;
      this.c.y = this.size / 2;
    }
    // this.canv.style.marginLeft=((this.vw-this.size)/2)+"px";
    // this.canv.style.marginTop=((this.vh-this.size)/2)+"px";

    this.draw();
  }


  draw = () => {
    if (this.step > this.numberOfPoints) {
      this.step = this.step % this.numberOfPoints;
    }
    this.points = [];
    // this.size=this.canv.width=this.canv.height=Math.min(this.vw, this.vh);

    var n = this.numberOfPoints;
    // this.c=new Point(this.size/2, this.size/2);


    var radius = this.size / 2;
    var theta = 2 * Math.PI / n;
    // console.log("Radius: " + radius);
    // console.log("theta: " + theta);
    var i = 0;

    for (i = 0; i < n; i++) {
      var y = radius * Math.sin(theta * i) + this.size / 2;
      var x = radius * Math.cos(theta * i) + this.size / 2;

      var k = new Point(x, y);
      // this.drawPoint(k);

      this.points.push(k);
    }

    this.ctx.lineWidth = 4;
    this.ctx.strokeStyle = "green";
    this.ctx.beginPath();
    this.ctx.clearRect(0, 0, this.size, this.size);
    this.ctx.beginPath();
    for (i = 0; i < n; i++) {
      var p1 = this.points[i];
      var p2 = this.points[(i + this.step) % n];

      var p = this.wAverage(p1, p2, 0.5);
      p = this.wAverage(p, this.c, 1 - this.springness);

      var cc = this.cCenter(p1, p2, p);
      var cr = this.distanceV(cc, p);

      var theta1 = Math.atan2(p1.y - cc.y, p1.x - cc.x);
      var theta2 = Math.atan2(p2.y - cc.y, p2.x - cc.x);

      if (this.step * 2 > n) theta1 = [theta2, theta2 = theta1][0];

      this.ctx.beginPath();
      this.ctx.arc(cc.x, cc.y, cr, theta2, theta1);
      this.ctx.stroke();
      this.ctx.closePath();
    }
    // this.ctx.beginPath();
    // this.ctx.stroke();
    // this.ctx.closePath();
  }

  cCenter = (a, b, c) => {
    var CyMnusAy = c.y - a.y;
    var ByMnusCy = b.y - c.y;
    var AyMnusBy = a.y - b.y;
    var ax2ay2 = a.x ** 2 + a.y ** 2;
    var bx2by2 = b.x ** 2 + b.y ** 2;
    var cx2cy2 = c.x ** 2 + c.y ** 2;
    var D = 2 * (a.x * (ByMnusCy) +
      b.x * (CyMnusAy) +
      c.x * (AyMnusBy));
    var Ux = ((ax2ay2) * (ByMnusCy) +
      (bx2by2) * (CyMnusAy) +
      (cx2cy2) * (AyMnusBy)) / D;
    var Uy = ((ax2ay2) * (b.x - c.x) +
      (bx2by2) * (c.x - a.x) +
      (cx2cy2) * (a.x - b.x)) / D * -1;

    return new Point(Ux, Uy);
  }

  distanceV = (v1, v2) => {
    return Math.sqrt((v1.x - v2.x) ** 2 + (v2.y - v1.y) ** 2);
  }

  wAverage = (v1, v2, k) => {
    return new Point(k * v1.x + (1 - k) * v2.x, k * v1.y + (1 - k) * v2.y);
  }

  drawPoint = (v, color = "black") => {
    this.ctx.beginPath();
    this.ctx.fillStyle = color;
    this.ctx.arc(v.x, v.y, this.size / 100, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.fillStyle = "black";
    this.ctx.closePath();
  }

  render() {
    return (
      <div className = "v-mid" >
        <canvas className = "w-100 App-logo"
          id = "canv" / >
      </div>
    );
  }
}

export default Spiro;