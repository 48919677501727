import React from 'react';
import { connect } from 'react-redux';
import Socials from '../../Data/Socials.json'
import Spiro from '../Spiro/Spiro'
import CardListBlog from '../Cards/CardListBlog'
import CardListProject from '../Cards/CardListProject'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom';
import {
  REQUEST_TYPE_BLOG,
  REQUEST_TYPE_PROJECT
} from '../../constants.js';

import { requestArticles } from '../../actions';

// tell me what piece of state i need to listen to and
// send to props,
const mapStateToProps = state => {
  return {
      blogIsPending: state.requestBlogs.isPending,
      blogPosts: state.requestBlogs.posts,
      blogError: state.requestBlogs.error,
      projectIsPending: state.requestProjects.isPending,
      projectPosts: state.requestProjects.posts,
      projectError: state.requestProjects.error,
  }
}

const malarkies = [
  <span>A little less dumb every day <span className="black-90" role="img" aria-label="yee haw">🤠</span></span>,
  <span>Donkey Kong is highly relatable as a protagonist; he just wants to eat bananas and chill</span>,
  <span>My epitaph would be a picture of me eating two dinners</span>,
  <span>Sure eating Babybel cheese is fine, but bring out a brick of cheddar in the lunchroom one time, and you're Rat Boy forever</span>,
  <span>Keep a gorilla on the desk for good luck <span className="black-90" role="img" aria-label="pray">🙏</span><span className="black-90" role="img" aria-label="gorilla">🦍</span></span>,
  <span>I don't need drugs, I'm high on fixing the dishwasher</span>,
  <span>I can't get my protein powder to dissolve in the eggnog, it's a supersaturated solution</span>
];

// What props should i listen to that are actions which need
// to be dispatched? dispatch sends actions to the reducer
const mapDispatchToProps = (dispatch) => {
  return {
      onRequestBlogs: () => dispatch(requestArticles(3, REQUEST_TYPE_BLOG)),
      onRequestProjects: () => dispatch(requestArticles(3, REQUEST_TYPE_PROJECT))
  }
}

class Home extends React.Component {
  componentDidMount() {
    this.props.onRequestBlogs();
    this.props.onRequestProjects();
    this.generateStupidPhrase();
  }
  render() {
  const { blogPosts, blogError, projectPosts, projectError } = this.props;
  var cardBlogs = [];
  var cardProjects = [];
  if (blogPosts && blogPosts.length > 0) {
      cardBlogs = blogPosts;
  }
  if (projectPosts && projectPosts.length > 0) {
      cardProjects = projectPosts;
  }
  var networks= Socials.map(function(network){
    return <a key={network.name} href={network.url}>
            <i className={'near-black dib h1 h2 w1 w2 mr3 ' + network.className}></i>
          </a>
  });

  this.generateStupidPhrase = () => {
    const min = 0.000001; // prevent accidentally going out of range, lol
    const max = malarkies.length;
    const rand = Math.floor(min + Math.random() * (max - min)); // e.g., 0-1 * 3 --> 0.0 -> 3.0. 
    this.randomQuote = malarkies[rand];
  }
  
  return (
    <div className="wrapper pt1 pa3 ph5-ns">
      <div className="pa3 mt2 mt3-l">
        <Spiro />
      </div>
      <div className="v-mid pa3">
        <header className="tl">
          <h1 className="f2 f1-l fw2 black-90 mb3 mt0">
            <div>Andy</div>
            <div>Schneider</div>
          </h1>
          <h3 className="f4 fw2 black-50 mt0">
            {this.randomQuote}
          </h3>
          <div className="flex pv2 ph0 tl">
            {networks}
          </div>
        </header>
      </div>
      <hr />
      <div>
        <CardListBlog cardBlogs = {cardBlogs}
                searchFilters = { false }
                message = {"Recent Blog Posts"}
                subMessage = {""}
                errorMessage = {blogError} />
          <div className="flex">
            <Nav.Link className="center f6 link br2 ph3 pv2 ml2 mb2 dib white bg-dark-gray as-hover-green"
                as={Link} to="/blog">See All</Nav.Link>
          </div>
      </div>
      <hr />
      <div>
        <CardListProject cardProjects = {cardProjects}
                searchFilters = { false }
                message = {"Recent Project Adventures"}
                subMessage = {""}
                errorMessage = {projectError} />
        <div className="flex">
          <Nav.Link className="center f6 link br2 ph3 pv2 ml2 mb2 dib white bg-dark-gray as-hover-green"
            as={Link} to="/projects">See All</Nav.Link>
        </div>
      </div>
    </div>
  );}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);