import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SocialButton extends React.Component {
  render() {
    const {url, classes, icon} = this.props;
    if(!url || !classes) {
      return null;
    }
    return (
      <a href={url} key={url}
        className="social-link br-50">
        <i className={'light-gray color-transition dib `mr3 ' + icon + ' ' + classes}></i>
      </a>
    );
  }
}

export default SocialButton;
