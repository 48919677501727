import React from 'react';

const ErrorGeneric = ({message}) => {
  return (
    <div>
      <header class="tc pv3 ph5 lh-copy">
          <h2 class="tc f3 f2-l fw1">{message}</h2>
      </header>
    </div>
  );
}

export default ErrorGeneric;