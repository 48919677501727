import {
  REQUEST_BLOGS_PENDING,
  REQUEST_BLOGS_SUCCESS,
  REQUEST_BLOGS_FAILED,
  REQUEST_PROJECTS_PENDING,
  REQUEST_PROJECTS_SUCCESS,
  REQUEST_PROJECTS_FAILED,
  SEND_EMAIL_PENDING,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILED,
  REQUEST_ARTICLE_PENDING,
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_ARTICLE_FAILED
} from './constants.js';


const initialStateLoadBlogs = {
  isPending: false,
  posts: [],
  error: ''
}

export const requestBlogs = (state = initialStateLoadBlogs, action = {}) => {
  switch (action.type) {
    case REQUEST_BLOGS_PENDING:
      return Object.assign({}, state, { isPending: true })
    case REQUEST_BLOGS_SUCCESS:
      return Object.assign({}, state, { posts: action.payload, isPending: false })
    case REQUEST_BLOGS_FAILED:
      return Object.assign({}, state, { error: action.payload, isPending: false })
    default:
      return state;
  }
}
const initialStateLoadProjects = {
  isPending: false,
  posts: [],
  error: ''
}

export const requestProjects = (state = initialStateLoadProjects, action = {}) => {
  switch (action.type) {
    case REQUEST_PROJECTS_PENDING:
      return Object.assign({}, state, { isPending: true })
    case REQUEST_PROJECTS_SUCCESS:
      return Object.assign({}, state, { posts: action.payload, isPending: false })
    case REQUEST_PROJECTS_FAILED:
      return Object.assign({}, state, { error: action.payload, isPending: false })
    default:
      return state;
  }
}

const initialStateLoadArticle = {
  isPending: false,
  data: {},
  error: ''
}

export const requestArticle = (state = initialStateLoadArticle, action = {}) => {
  switch (action.type) {
    case REQUEST_ARTICLE_PENDING:
      return Object.assign({}, state, { isPending: true })
    case REQUEST_ARTICLE_SUCCESS:
      return Object.assign({}, state, { data: action.payload, isPending: false })
    case REQUEST_ARTICLE_FAILED:
      return Object.assign({}, state, { error: action.payload, isPending: false })
    default:
      return state;
  }
}

const initialStateSendEmail = {
  isPending: false,
  data: '',
  error: ''
}

export const sendEmail = (state = initialStateSendEmail, action = {}) => {
  switch (action.type) {
    case SEND_EMAIL_PENDING:
      return Object.assign({}, state, { isPending: true })
    case SEND_EMAIL_SUCCESS:
      return Object.assign({}, state, { data: action.payload, isPending: false })
    case SEND_EMAIL_FAILED:
      return Object.assign({}, state, { error: action.payload, isPending: false })
    default:
      return state;
  }
}