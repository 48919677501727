import React from 'react';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../../images/shaqLogo.png';

import { Link } from 'react-router-dom';
import './Navigation.css'

import {
  MAIN_CONTENT_ID
} from '../../constants.js';

class Navigation extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll, true);
  }
  resizeHeaderOnScroll(event) {
    var distanceY = window.pageYOffset || document.documentElement.scrollTop;
    if(!distanceY && event.target.id === MAIN_CONTENT_ID) {
      distanceY = event.target.scrollTop;
    }
    const shrinkOn = 100,
      header = document.getElementById("nav-header"),
      content = document.getElementById(MAIN_CONTENT_ID);

    if (distanceY > shrinkOn) {
      header.classList.add("pv0");
      header.classList.add("nav-contracted");
      header.classList.remove("pv2");
      header.classList.remove("nav-expanded");
      content.classList.add("nav-contracted-margin")
      content.classList.remove("nav-expanded-margin")
    } else {
      header.classList.add("pv2");
      header.classList.add("nav-expanded");
      header.classList.remove("pv0");
      header.classList.remove("nav-contracted");
      content.classList.add("nav-expanded-margin")
      content.classList.remove("nav-contracted-margin")
    }
  }
  render() {
  return (
    <div id="nav-header" className="hella-h-w-transitions-03 sticky header-bg pv2 ph2">
      <div
          className="flex justify-content-between  mw7-ns mw8-l center">
          <Navbar.Brand className="light-gray as-hover-green"
            as={Link} to="/">
            <img
              alt=""
              src={logo}
              height="30"
              object-fit="cover"
              className="d-inline-block align-top minwid35"
            />{' '}
            <span className="hide-narrow">
              Andy Schneider
            </span>
          </Navbar.Brand>

          <div className="flex justify-content-between">
            <Nav.Link className="light-gray as-hover-green"
              as={Link} to="/about">About</Nav.Link>
            <Nav.Link className="light-gray as-hover-green"
              as={Link} to="/projects">Projects</Nav.Link>
            <Nav.Link className="light-gray as-hover-green"
              as={Link} to="/blog">Blog</Nav.Link>
          </div>
      </div>
    </div>
  );
  }
}

export default Navigation;