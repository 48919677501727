import React from 'react';
import { connect } from 'react-redux';
import CardListProject from '../Cards/CardListProject'
import { requestArticles } from '../../actions';

import {
  REQUEST_TYPE_PROJECT
} from '../../constants.js';

const mapStateToProps = state => {
  return {
      projectIsPending: state.requestProjects.isPending,
      projectPosts: state.requestProjects.posts,
      projectError: state.requestProjects.error,
  }
}


// What props should i listen to that are actions which need
// to be dispatched? dispatch sends actions to the reducer
const mapDispatchToProps = (dispatch) => {
  return {
      onRequestProjects: () => dispatch(requestArticles(3, REQUEST_TYPE_PROJECT))
  }
}

class Projects extends React.Component {
  componentDidMount() {
    this.props.onRequestProjects();
  }
  render () {
    const { projectPosts, projectError } = this.props;
    var cardProjects = [];
    if (projectPosts && projectPosts && projectPosts.length > 0) {
        cardProjects = projectPosts;
    }
    return (
      <div className="pt1 pa3 ph5-ns">
        <CardListProject cardProjects = {cardProjects}
          searchFilters = { false }
          message = {"Project Adventures"}
          subMessage = {"These are essentially slightly more impressive blog posts"}
          errorMessage = {projectError} />
      </div>
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);