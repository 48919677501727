import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import 'tachyons';
import { requestBlogs, requestProjects, requestArticle, sendEmail } from './reducers';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';


// import '@fortawesome/fontawesome-free/css/all.min.css';
// import '@fortawesome/free-brands-svg-icons/css/all.min.css';
import 'font-awesome/css/font-awesome.min.css';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faLinkedin, faFacebook, faInstagram, faGitlab, faGithub, faItchIo, faTwitter } from '@fortawesome/free-brands-svg-icons'
// import { faUser, faStar, faGrinSquintTears, faFlag, faEnvelope } from '@fortawesome/free-regular-svg-icons'
// import { faGlobe, faLink, faCoffee } from '@fortawesome/free-solid-svg-icons'


// library.add(faUser, faStar, faGrinSquintTears, faFlag, faEnvelope,
//   faFacebook, faLinkedin, faInstagram, faGitlab, faGithub, faItchIo, faTwitter,
//   faGlobe, faLink, faCoffee);

// Middleware Used to monitor actions
const actionLogger = false;
var logger
if (actionLogger) {
  logger = createLogger();
}
// Helps accomodate many reducers
const rootReducer = combineReducers({
    requestBlogs,
    requestProjects,
    requestArticle,
    sendEmail
  })
  // The order of middleware matters! it goes through thunk THEN the logger!
var store;
if (actionLogger) {
  store = createStore(rootReducer, applyMiddleware(thunkMiddleware, logger));
} else {
  store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
}

ReactDOM.render(
  <Provider store = { store } >
    <BrowserRouter >
        <App / >
    </BrowserRouter>
  </Provider > ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();