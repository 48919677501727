import React from 'react';
import CardProject from './CardProject';
import ErrorGeneric from '../Utilities/ErrorGeneric';
import CuteLoader from '../Utilities/CuteLoader';

const CardListProject = ({ cardProjects, searchFilters, message, subMessage, errorMessage }) => {
    var cardComponent = <CuteLoader />;
    if (errorMessage) {
      cardComponent = <ErrorGeneric message="Error loading projects! How embarrassing!" />;
      console.log(errorMessage);
    }
    if (cardProjects.length > 0) {
      cardComponent = cardProjects.map((article, i) => {
          return <CardProject key={i} path={article.Path} title={article.Title} date={article.CreatedDate}
                              imagelink={article.HeaderImg} description={article.Description}
                              pagename={article.PageName}/>
      });
    }
    return (
      <div className="mb1 pa3">
          <h2 className="f2 fw2 black-90 mb3 mt0">
            <div>{message}</div>
          </h2>
          <h3 className="f4 fw2 black-50 mt0">
            {subMessage}
          </h3>
          <div className="center flex flex-wrap justify-content-center">
            { cardComponent }
          </div>
      </div>
    );
}

export default CardListProject