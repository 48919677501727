import React from 'react';
import Socials from '../../Data/Socials.json'
import './Footer.css'

const Footer = () => {
  var networks= Socials.map(function(network){
    return <a key={network.name} href={network.url}>
            <i className={'gray dib h2 w2 br-100 mr3 ' + network.className}></i>
          </a>
  })

  return (
    <div>
      <div className="pv2 ph3 ph5-ns tc sticky-footer">
        {networks}
      </div>
    </div>
  );
}

export default Footer;