import React, { Component } from 'react';

import Main from './Main';
import Footer from './Components/Footer/Footer';
import Navigation from './Components/Navigation/Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import 'hamburgers/dist/hamburgers.css'

import {
  MAIN_CONTENT_ID
} from './constants.js';

class App extends Component {

  componentDidMount(){
    AOS.init();
  }

  handleScroll = (event) => {
    AOS.refresh();
  }

  render() {
    return (
      <div>
        <Navigation />
        <div id={MAIN_CONTENT_ID} className="style-main-gutter nav-expanded-margin hella-h-w-transitions-03" onScroll={this.handleScroll}>
          <ScrollToTop>
            <Main  />
          </ScrollToTop>
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;