import React from 'react';
import { connect } from 'react-redux';
import CardListBlog from '../Cards/CardListBlog'
import { requestArticles } from '../../actions';

import {
  REQUEST_TYPE_BLOG
} from '../../constants.js';

const mapStateToProps = state => {
  return {
    blogIsPending: state.requestBlogs.isPending,
    blogPosts: state.requestBlogs.posts,
    blogError: state.requestBlogs.error,
  }
}

// What props should i listen to that are actions which need
// to be dispatched? dispatch sends actions to the reducer
const mapDispatchToProps = (dispatch) => {
  return {
    onRequestBlogs: () => dispatch(requestArticles(3, REQUEST_TYPE_BLOG))
  }
}

class Blog extends React.Component {
  componentDidMount() {
    this.props.onRequestBlogs();
  }
  render() {
    const { blogPosts, blogError } = this.props;
    var cardBlogs = [];
    if (blogPosts && blogPosts && blogPosts.length > 0) {
      cardBlogs = blogPosts;
    }
    return (
      <div className="pt1 pa3 ph5-ns">
        <CardListBlog cardBlogs = { cardBlogs }
        searchFilters = { false }
        message = { "Blog Posts" }
        subMessage = { "An assortment of short adventures and side quests" }
        errorMessage = {blogError}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);