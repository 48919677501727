import React from 'react';

const Error404 = () => {
  return (
    <div>
    <header class="tc ph5 lh-copy">
        <h1 class="f1 f-headline-l code mb3 fw9 dib tracked-tight green">404</h1>
        <h2 class="tc f1-l fw1">I have never met that page in my life</h2>
    </header>
    </div>
  );
}

export default Error404;