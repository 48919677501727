import React from 'react';
import './cards.css'

class CardAdventure extends React.Component {
  render() {
    const images = require.context('../../images', true);
    let img = images('./' + this.props.imagelink);
    return (
      <div className="br2 mv2 flex" data-aos="fade-up">
        <div className='br2 shadow-3'>
        <a href={this.props.link} className="link">
          <div style={{backgroundImage: "url(" + img + ")"}}
              className="half-width-card flex flex-column link hide-child br2 cover bg-center">
            <span className="br2 br--top f4 white dtc tc v-mid w-100 h-100 child bg-black-50 pa2 pt4">
              {this.props.description}
            </span>
            <div className="br2 br--bottom ph2 flex bg-black-50">
              <h5><i className={'fa link white fa-fw ' + this.props.icon}></i>&nbsp;</h5>
              <h5 className="link white">{this.props.year}&nbsp;-&nbsp;</h5>
              <h5 className="link white">{this.props.title}</h5>
            </div>
          </div>
        </a>
        </div>
      </div>
    );
  }
}

export default CardAdventure;
