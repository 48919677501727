import React from 'react';
import './resume.css';

class CardResume extends React.Component {
  render() {
    const { title, timeline, company, location, factoids, icon, color } = this.props;
    var coolFacts= factoids.map(function(fact){
      return <li className="list flex" key={fact}>
              <i className="fa fa-chevron-right f6 pr1"></i><p>{fact}</p>
            </li>
    });
    return (
      <div className="timeline-component flex mb4 shift-left w-100">
        <div className={"circle-boi mr2 " + color}>
          <i className={'fa white fa-fw ' + icon}></i>
        </div>
        <div className="timeline-panel ml2 shadow-3">
          <div className="">
            <div className="flex">
              <h4>{title}</h4>
            </div>
            <div className="mt2 boxy-header-5">
              <h6>{timeline}</h6>
            </div>
            <div className="mt0 flex i">
              <h6 className="black fw6">{company}&nbsp;&nbsp;</h6>&nbsp;
              <h6>{location}</h6>
            </div>
            <div>
              <ul className="mv1 pl2">
                {coolFacts}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardResume;
