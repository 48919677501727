import React from "react";
import Socials from "../../Data/Socials.json";
import "./about.css";
import "aos/dist/aos.css";
import CardResume from "./Resume/CardResume";
import CardAdventure from "../Cards/CardAdventure";
import CardFriend from "../Cards/CardFriend";
import ContactForm from "../ContactForm/ContactForm";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedMenu: false,
    };
  }

  toggleNarrowMenu = (event) => {
    const currentState = this.state.expandedMenu;
    this.setNarrowShowHide(!currentState);
  };

  hideNarrowMenu = (event) => {
    if (this.state.expandedMenu) {
      this.setNarrowShowHide(false);
    }
  };

  setNarrowShowHide = (setting) => {
    this.setState({ expandedMenu: setting });
  };

  render() {
    const images = require.context("../../images", true);
    const files = require.context("../../files", true);
    let resumePDF = files("./AndrewSchneiderResume2023.pdf");
    let profilepic = images("./profilepic.jpg");
    // let andyPic1 = images("./profilepic1.png");
    let andyPic2 = images("./Andy_23.png");
    let sadcat = images("./sadcowboycat.jpg");
    var networks = Socials.map(function (network) {
      return (
        <a key={network.name} href={network.url} className="social-link br-50">
          <i className={"light-gray dib `mr3 " + network.className}></i>
        </a>
      );
    });
    return (
      // <div className="pt1 pa3 ph5-ns">
      <div className="flex" onClick={this.hideNarrowMenu}>
        <div className="header-hamburger narrow-display-only dtc">
          <button
            className={
              this.state.expandedMenu
                ? "hamburger br-50 hamburger--collapse is-active"
                : "hamburger br-50 hamburger--collapse"
            }
            type="button"
            onClick={this.toggleNarrowMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          {/* <i className={this.state.expandedMenu ? 'fa fa-times `mr3 fa-fw' : 'fa fa-bars `mr3 fa-fw'}></i> */}
        </div>
        <header
          id="header"
          className={this.state.expandedMenu ? "expand-narrow" : null}
        >
          <div
            id="header-content"
            className={
              this.state.expandedMenu
                ? "flex-column flex expand-narrow"
                : "flex-column flex"
            }
          >
            <div id="profile" className="mt2">
              <img
                className="img-fluid br-50"
                alt="Andy Schneider"
                src={profilepic}
              />
              {/* <h1 className="f3 fw6 tc light-gray no-margin no-padding">Andy Schneider</h1> */}

              <div className="pt3 pb2 tc">{networks}</div>
              <nav className="pt3 nav-menu">
                <ul>
                  <li>
                    <a className="hover-white" href="#about">
                      <i className="fa fa-user fa-fw"></i>About
                    </a>
                  </li>
                  <li>
                    <a className="hover-white" href="#resume">
                      <i className="fa fa-star fa-fw"></i>Resume
                    </a>
                  </li>
                  <li>
                    <a className="hover-white" href="#friends">
                      <i className="fa fa-users fa-fw"></i>Friends
                    </a>
                  </li>
                  <li>
                    <a className="hover-white" href="#adventures">
                      <i className="fa fa-flag fa-fw"></i>Adventures
                    </a>
                  </li>
                  <li>
                    <a className="hover-white" href="#contact">
                      <i className="fa fa-envelope fa-fw"></i>Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        <div id="main">
          <div id="about" className="pt4 overflow-hidden">
            <div className="container">
              <div
                className="section-title aos-init aos-animate"
                data-aos="fade-up"
              >
                <h2>About</h2>
                <p>
                  Hi, I'm Andy Schneider, a University of Calgary software
                  engineering graduate!
                  <br />
                  Now that I've left the educational system behind, I like
                  spending time on personal projects or making games as a part
                  of{" "}
                  <a
                    href="https://www.ninetypercentstudios.com/"
                    className="as-hover-green"
                  >
                    90% Studios!
                  </a>
                  <br />
                  My mission is to have fun creating cool stuff, from games to
                  greenhouses and everything in between.
                </p>
              </div>

              <div className="row">
                <div
                  className="col-lg-5 aos-init aos-animate hide-child"
                  data-aos="fade-right"
                >
                  <img
                    src={andyPic2}
                    className="img-fluid"
                    alt="Andy icon, By Denny Truong!"
                  />
                  <div className="tc center bottom-0 dtc child">
                    <a
                      href="https://twitter.com/90PercentDenny"
                      className="as-hover-green"
                    >
                      Art by Denny Truong!
                    </a>
                  </div>
                </div>
                <div
                  className="col-lg-7 pt-4 pt-lg-0 content aos-init aos-animate"
                  data-aos="fade-left"
                >
                  <h3>Stats</h3>
                  <p className="font-italic">
                    What would be on my hockey card:
                  </p>
                  <div className="row">
                    <div className="col-lg-11">
                      <ul className="pl3">
                        <li className="list">
                          <i className="fa fa-chevron-right"></i>{" "}
                          <strong>Favorite languages:</strong> Go, Ruby, TS, C#,
                          Python
                        </li>
                        <li className="list">
                          <i className="fa fa-chevron-right"></i>{" "}
                          <strong>Other languages:</strong> C++, PHP, SQL, Java,
                          English
                        </li>
                        <li className="list">
                          <i className="fa fa-chevron-right"></i>{" "}
                          <strong>Favorite Sports:</strong> Hockey,
                          Backpacking(?)
                        </li>
                        <li className="list">
                          <i className="fa fa-chevron-right"></i>{" "}
                          <strong>Favorite Fruit:</strong> Apples but also
                          watermelon
                        </li>
                        <li className="list">
                          <i className="fa fa-chevron-right"></i>{" "}
                          <strong>Favorite meme: </strong>
                          <img
                            className="w2"
                            alt="Sad cowboy cat"
                            src={sadcat}
                          />
                        </li>
                        <li className="list">
                          <i className="fa fa-chevron-right"></i>{" "}
                          <strong>Subs or Dubs:</strong> Subs
                        </li>
                        <li className="list">
                          <i className="fa fa-chevron-right"></i>{" "}
                          <strong>Spicy Food:</strong> Sure ok
                        </li>
                        <li className="list">
                          <i className="fa fa-chevron-right"></i>{" "}
                          <strong>Fitness:</strong> Fitness whole pizza in my
                          mouth
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div id="resume" className="pt4 overflow-hidden">
            <div className="container">
              <div className="mb2">
                <a
                  className="link as-hover-green"
                  href={resumePDF}
                  download="AndrewSchneiderResume2023.pdf"
                >
                  Here's a PDF of my resume, if you prefer!
                </a>
              </div>
              <div className="section-title " data-aos="fade-up">
                <h2>Resume</h2>
              </div>
              <div
                className="flex justify-end timeline-piece"
                data-aos="fade-up"
              >
                <CardResume
                  title="Software Developer"
                  timeline="2022 June - Present"
                  company="Shopify"
                  location="Calgary, AB (remote)"
                  icon="fa-suitcase"
                  color="bg-light-red"
                  factoids={[
                    "Primarily frontend development with React+TypeScript on an agile team",
                    "Development with Ruby+Rails",
                    "TypeScript, React, GraphQL, Ruby, MySQL",
                  ]}
                />
              </div>
              <div
                className="flex justify-end timeline-piece"
                data-aos="fade-up"
              >
                <CardResume
                  title="Game Developer"
                  timeline="2018 January - Present"
                  company="90% Studios"
                  location="Calgary, AB"
                  icon="fa-gamepad"
                  color="bg-light-blue"
                  factoids={[
                    "Programming and designing games as part of an indie duo",
                    "Created Panel Royale in Unity, a battle-royale match-3 game with 100+ MAU",
                    "Helped build the underlying inventory and character interaction systems for Cool Kid Cody and Purrfect Apawcalypse (RenPy)",
                  ]}
                />
              </div>
              <div
                className="flex justify-end timeline-piece"
                data-aos="fade-up"
              >
                <CardResume
                  title="Full Stack Software Engineer, E.I.T."
                  timeline="2019 June - 2022 May"
                  company="Circle Cardiovascular Imaging"
                  location="Calgary, AB"
                  icon="fa-suitcase"
                  color="bg-light-red"
                  factoids={[
                    "Facilitated efficient regular meetings and cross-team communication as a (certified!) scrum master on an agile team of 5-10 developers creating a reporting web application",
                    "Built deployment-specific customizations of the product using Docker for OEM sites with unique constraints (host OS, server availability limitations)",
                    "Developed new frontend features in Angular + AngularJS based on user feedback, including report headers, user list, automated citations",
                    "Became the subject matter expert in localization, streamlining the process for updating translations and supporting the product in new languages",
                    "Migrated a backend data ingress pipeline from PHP to Go, utilizing TDD",
                    "Go, JavaScript, TypeScript, Angular, PHP, MSSQL, SQLite, Jira, TeamCity",
                  ]}
                />
              </div>
              <div
                className="flex justify-end timeline-piece"
                data-aos="fade-up"
              >
                <CardResume
                  title="Technical Support Contractor"
                  timeline="2020 February - 2020 June"
                  company="Partake Brewing"
                  location="Calgary, AB"
                  icon="fa-suitcase"
                  color="bg-light-red"
                  factoids={[
                    "Safely migrated live WordPress e-commerce site with 3000+ customers to Shopify, improving support and customer experience (PHP, Ruby)",
                    "Developed tools to analyze user funnels of buying patterns to guide marketing campaigns. E.g., validated that buying sampler packs leads to subscriptions :D",
                  ]}
                />
              </div>
              <div
                className="flex justify-end timeline-piece"
                data-aos="fade-up"
              >
                <CardResume
                  title="Bachelor's Degree, Software Engineering"
                  timeline="2014 September - 2019 May"
                  company="University of Calgary"
                  location="4.0"
                  icon="fa-graduation-cap"
                  color="bg-light-green"
                  factoids={[
                    "Specialization in biomedical engineering",
                    "MicroProcessinG (MPG) - PCB soldering, embedded systems development",
                    "Bennie Vorster Memorial Scholarship in Software Engineering",
                    "APEGA Past Presidents' Gold Medal in Software Engineering",
                    "Governor General's Academic Silver Medal",
                  ]}
                />
              </div>
              <div
                className="flex justify-end timeline-piece"
                data-aos="fade-up"
              >
                <CardResume
                  title="Software Engineering Intern"
                  timeline="2017 May - 2018 August"
                  company="Garmin International"
                  location="Cochrane, AB"
                  icon="fa-suitcase"
                  color="bg-light-red"
                  factoids={[
                    "Optimized the optical heart rate algorithm with a genetic algorithm approach (C++, Python), increasing accuracy on Garmin’s fitness wearables",
                    "Developed a C# application for visualizing effects of algorithm changes on heart rate calculation accuracy",
                    "Implemented a wireless PID motor controlled test fixture in C for evaluating optical heart rate hardware, reducing manual engineering test time by 90%",
                  ]}
                />
              </div>
              <div
                className="flex justify-end timeline-piece"
                data-aos="fade-up"
              >
                <CardResume
                  title="Undergraduate Researcher"
                  timeline="2016 May - 2016 August"
                  company="Applied Electromagnetics Group"
                  location="University of Calgary"
                  icon="fa-flask"
                  color="bg-light-green"
                  factoids={[
                    "Piloted an independent project utilizing C++/Qt and Python which measured and visualized the signals and interference in an array of microwave antennas",
                  ]}
                />
              </div>
            </div>
          </div>
          <div id="friends" className="pt4 overflow-hidden">
            <div className="container">
              <div className="section-title " data-aos="fade-up">
                <h2>Friends</h2>
                <p>
                  A few cool people with an online presence I want to give a
                  shout out to!
                </p>
              </div>
              <div className="flex justify-end" data-aos="fade-up">
                <CardFriend
                  Name="Denny Truong"
                  color="light-blue"
                  imagelink="Denny_19.png"
                  socialLinks={{
                    website: "https://www.ninetypercentstudios.com/",
                    linkedin:
                      "https://www.linkedin.com/in/denise-truong-8b962266/",
                    twitter: "https://twitter.com/90PercentDenny",
                    itch: "https://90percentstudios.itch.io/",
                    twitch: "https://www.twitch.tv/90percentstudios",
                    // "patreon": "https://www.patreon.com/90percentstudios",
                    instagram: "https://www.instagram.com/90percentdenny/",
                  }}
                  description={
                    <p>
                      Denny is a super skilled artist, designer, game developer,
                      musician, and writer! We make indie games together as{" "}
                      <a
                        href="https://www.ninetypercentstudios.com/"
                        className="as-hover-green"
                      >
                        90% Studios!
                      </a>{" "}
                      She created Cool Kid Cody, the Purrfect Apawcalypse
                      Series, and did all the artwork for my baby, Panel Royale.
                      Follow her on twitch or twitter to keep up with all the
                      evil things she's working on!
                    </p>
                  }
                />
              </div>
              <div className="flex justify-end" data-aos="fade-up">
                <CardFriend
                  Name="Ivan Chow"
                  color="light-purple"
                  imagelink="ivan.jpg"
                  socialLinks={{
                    linkedin: "https://www.linkedin.com/in/ivanchow94/",
                  }}
                  description={
                    <p>
                      Ivan is a Senior Software Engineer at Garmin! We worked
                      together briefly during my internship there from
                      2017-2018, and he supervised my capstone project! I pretty
                      much only know how to skate because he encouraged me to
                      play ice hockey on our lunch breaks. We still play ball
                      hockey together sometimes!
                    </p>
                  }
                />
              </div>
              <div className="flex justify-end" data-aos="fade-up">
                <CardFriend
                  Name="Simon Tam"
                  color="mid-gray"
                  imagelink="simon.jpg"
                  socialLinks={{
                    website: "",
                    linkedin: "https://www.linkedin.com/in/sctam/",
                    instagram: "https://www.instagram.com/sctamca/?hl=en",
                  }}
                  description={
                    <p>
                      Simon is a software engineer at Microsoft! He was my
                      mentor during my time at Garmin, and I learned a lot of
                      engineer-stuff and real-life-stuff from him. You can find
                      his very good very evil cute boi Bolt on instagram!
                    </p>
                  }
                />
              </div>
              <div className="flex justify-end" data-aos="fade-up">
                <CardFriend
                  Name="Byron Seto"
                  color="red"
                  imagelink="byron.jpg"
                  socialLinks={{
                    website: "http://byronseto.com/home",
                    github: "https://github.com/bseto",
                    linkedin: "https://www.linkedin.com/in/byronseto/",
                  }}
                  description={
                    <p>
                      Byron is a Software Engineer at High Tech Genesis! We
                      worked together at Circle Cardiovascular Imaging from
                      2019-2022. He's a driven and creative programmer who loves
                      to try out new technologies! Thanks to him I'm a fan of
                      Vim and Go!
                    </p>
                  }
                />
              </div>
              {/* <div className="flex justify-end" data-aos="fade-up">
                <CardFriend
                  Name="Chelsea Finnigan"
                  color="b--light-purple"
                  imagelink="chelsea.jpg"
                  socialLinks={{
                    website: "https://chelsea-rose.ca/",
                    linkedin: "https://www.linkedin.com/in/chelsea-f-b7135a98/",
                    twitter: "https://twitter.com/the_finnigan",
                    instagram:
                      "https://www.instagram.com/adventuring_with_chelsea",
                  }}
                  description={
                    <p>
                      Chelsea was my wife, who passed away from brain cancer in
                      early 2022. Check out her website if you'd like to read
                      about her battle.
                    </p>
                  }
                />
              </div> */}
            </div>
          </div>
          <div id="adventures" className="pt4 overflow-hidden">
            <div className="container">
              <div className="section-title" data-aos="fade-up">
                <h2>Adventures</h2>
                <p>
                  A short list of projects I've been involved with recently and
                  want to share!
                </p>
              </div>
              <div className="flex flex-wrap justify-around col-lg-12">
                <CardAdventure
                  icon="fa-globe"
                  year="2022"
                  title="GardenWatch"
                  description="Some sensors, an Arduino, cool graphs of data from my greenhouse!"
                  imagelink="gardenwatch.gif"
                  link="https://gardenwatch.ca/garden/calgary-0"
                />
                <CardAdventure
                  icon="fa-gamepad"
                  year="2022"
                  title="Cool Kid Cody"
                  description="I provided some super tech support for the new CKC Series by Denny!"
                  imagelink="coolkidcody2022.webp"
                  link="https://store.steampowered.com/bundle/25402/Cool_Kid_Cody__Season_1/"
                />
                <CardAdventure
                  icon="fa-gamepad"
                  year="2020"
                  title="Panel Royale"
                  description="An in-browser match-3 battle-royale game!"
                  imagelink="panelroyale.gif"
                  link="https://andyschneider.ca/page/panel-royale"
                />
                <CardAdventure
                  icon="fa-gamepad"
                  year="2020"
                  title="Purrfect Apawcalypse"
                  description="This series is like 99.90% Denny's work, I just contributed some super tech support."
                  imagelink="pa.png"
                  link="https://www.ninetypercentstudios.com/purrfect-apawcalypse"
                />
                <CardAdventure
                  icon="fa-gamepad"
                  year="2018"
                  title="CKC Character Select"
                  description="Select screen for the characters in Denny's Cool Kid Cody universe!"
                  imagelink="ckcselect.png"
                  link="https://90percentstudios.itch.io/character-select"
                />
                <CardAdventure
                  icon="fa-gamepad"
                  year="2018"
                  title="CKC Demo"
                  description="This game was my first major project in Unity. It's kinda crappy but we learned a lot!"
                  imagelink="ckcturqfg.webp"
                  link="https://www.ninetypercentstudios.com/ckc-totallyunreal"
                />
              </div>
            </div>
          </div>
          <div id="contact" className="pt4 overflow-hidden">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Contact</h2>
              </div>
              <ContactForm />
            </div>
          </div>
          <div id="spacer" className="pb5"></div>
        </div>
      </div>
    );
  }
}

export default About;
