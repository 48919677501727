import React from "react";
import SocialButton from "./SocialButton";

const twitterClass = "social-twitter";
const linkedinClass = "social-linkedin";
const instagramClass = "social-instagram";
const gitlabClass = "social-gitlab";
const facebookClass = "";
const tumblrClass = "";
const websiteClass = "social-website";
const itchClass = "social-itch";
const githubClass = "social-github";
const twitchClass = "social-twitch";
const patreonClass = "social-patreon";

const twitterIcon = "fa fa-twitter";
const linkedinIcon = "fa fa-linkedin";
const instagramIcon = "fa fa-instagram";
const gitlabIcon = "fa fa-gitlab";
const facebookIcon = "fa fa-facebook";
const tumblrIcon = "fa fa-tumblr";
const websiteIcon = "fa fa-globe";
const itchIcon = "fa fa-gamepad";
const githubIcon = "fa fa-github";
const twitchIcon = "fa fa-twitch";
const patreonIcon = "fa fa-patreon";

class SocialButtons extends React.Component {
  render() {
    const {
      twitter,
      linkedin,
      instagram,
      gitlab,
      facebook,
      tumblr,
      website,
      itch,
      twitch,
      github,
      patreon,
    } = this.props.socialLinks;
    return (
      <div className={"pt1 pb2 " + this.props.justifyClass}>
        <SocialButton url={website} classes={websiteClass} icon={websiteIcon} />
        <SocialButton url={gitlab} classes={gitlabClass} icon={gitlabIcon} />
        <SocialButton url={github} classes={githubClass} icon={githubIcon} />
        <SocialButton url={twitch} classes={twitchClass} icon={twitchIcon} />
        <SocialButton url={itch} classes={itchClass} icon={itchIcon} />
        <SocialButton url={patreon} classes={patreonClass} icon={patreonIcon} />
        <SocialButton url={twitter} classes={twitterClass} icon={twitterIcon} />
        <SocialButton
          url={instagram}
          classes={instagramClass}
          icon={instagramIcon}
        />
        <SocialButton
          url={linkedin}
          classes={linkedinClass}
          icon={linkedinIcon}
        />
        <SocialButton
          url={facebook}
          classes={facebookClass}
          icon={facebookIcon}
        />
        <SocialButton url={tumblr} classes={tumblrClass} icon={tumblrIcon} />
      </div>
    );
  }
}

export default SocialButtons;
