import React from 'react';
import './contactform.css';
import { connect } from 'react-redux';
import { sendEmail } from '../../actions';
import CuteLoader from '../Utilities/CuteLoader';


const mapStateToProps = state => {
  return {
      sendEmailPending: state.sendEmail.isPending,
      sendEmailResponse: state.sendEmail.data,
      sendEmailError: state.sendEmail.error,
  }
}


// What props should i listen to that are actions which need
// to be dispatched? dispatch sends actions to the reducer
const mapDispatchToProps = (dispatch) => {
  return {
      onSendEmail: (data) => dispatch(sendEmail({
        name: data.name,
        email: data.email,
        message: data.message
      }))
  }
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      message: '',
      email: '',
      sent: false,
      buttonText: 'Send Message'
    };
  }

  formSubmit = (e) => {
    e.preventDefault()

    this.setState({
      sent: true
    })
    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    }

    this.props.onSendEmail(data);
  }

  render() {
    const { sendEmailPending, sendEmailResponse, sendEmailError } = this.props;
    return (
      <div className="col-lg-12">
        <form className="w-100 pa4 bg-white shadow-3" onSubmit={ (e) => this.formSubmit(e)}>
          <div className="flex">
            <div className="form-group">
              <label className="dib mb1 f4" htmlFor="message-name">Your Name</label>
              <input onChange={e => this.setState({ name: e.target.value})}
                        name="name" className="form-control input-area" type="text"
                        placeholder="" required value={this.state.name}/>
            </div>
            <div className="form-group">
              <label className="dib mb1 f4" htmlFor="message-email">Your Email</label>
              <input onChange={(e) => this.setState({ email: e.target.value})}
                        name="email" className="form-control input-area" type="email"
                        placeholder="" required value={this.state.email} />
            </div>
          </div>
          <div className="form-group">
            <label className="dib mb1 f4" htmlFor="message-input">Your Message</label>
            <textarea onChange={e => this.setState({ message: e.target.value})}
                      name="message" className="form-control input-area" type="text"
                      placeholder="" required value={this.state.message} />
          </div>
          <div className="button-container">
            { this.state.sent ?
                sendEmailPending ?
                  <CuteLoader />
                :
                  sendEmailError ?
                    <div className="tc f5 dark-red">{sendEmailError}</div>
                  :
                    <div className="tc f5">{sendEmailResponse}</div>
              :
                  <button className="center f6 bn br2 ph3 pv2 white bg-dark-gray as-hover-green link nav-link"
                type="submit">{ this.state.buttonText }</button>
              }
          </div>
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
