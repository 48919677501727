import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './Components/Home/Home';
import About from './Components/About/About';
import Error404 from './Components/Utilities/Error404';
import Projects from './Components/Projects/Projects';
import Blog from './Components/Blog/Blog';
import Page from './Components/Page/Page';


class Main extends React.Component {
  render() {
    return (
      <div className="hella-h-w-transitions-03 mw7-ns mw8-l center bg-light-gray mb-5 min-h-100" >
      <Switch > {/* The Switch decides which component to show based on the current URL.*/}
        <Route path="/" component={Home} exact/>
        <Route path="/about" component={About}/>
        <Route path="/projects" component={Projects}/>
        <Route path="/blog" component={Blog}/>
        <Route path="/page/:pageName" component={Page}/>
        <Route component={Error404}/>
      </Switch>
      </div>
    );
  }
}

export default Main;