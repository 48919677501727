import React from 'react';
import { withRouter } from "react-router";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    // console.log("this.props.location:");
    // console.log(this.props.location.pathname);
    // console.log("prevProps.location:");
    // console.log(prevProps.location.pathname);
    // console.log((this.props.location.pathname !== prevProps.location.pathname));
    if (this.props.location.pathname !== prevProps.location.pathname) {
      document.querySelector('#main-content').scrollTo(0,0)
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);