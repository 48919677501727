import {
  REQUEST_BLOGS_PENDING,
  REQUEST_BLOGS_SUCCESS,
  REQUEST_BLOGS_FAILED,
  REQUEST_PROJECTS_PENDING,
  REQUEST_PROJECTS_SUCCESS,
  REQUEST_PROJECTS_FAILED,
  SEND_EMAIL_PENDING,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILED,
  REQUEST_TYPE_BLOG,
  REQUEST_TYPE_PROJECT,
  REQUEST_ARTICLE_PENDING,
  REQUEST_ARTICLE_SUCCESS,
  REQUEST_ARTICLE_FAILED
} from './constants.js';

function apiDomain() {
  const production = process.env.NODE_ENV === 'production'
  return production ? 'https://andyschneider.ca/api/' : 'http://localhost:5000/api/'
}

// We pass the dispatch so that we have access to it!
// We want this to return a function! so we'll make it higher order
export const requestArticles = (maxPosts, type) => (dispatch) => {
  if (type === REQUEST_TYPE_BLOG) {

    dispatch({ type: REQUEST_BLOGS_PENDING });
    fetch(apiDomain() + "articles/Blog")
      .then(response => response.json())
      .then(posts => dispatch({ type: REQUEST_BLOGS_SUCCESS, payload: posts.data }))
      .catch(error => dispatch({ type: REQUEST_BLOGS_FAILED, payload: error }));
  } else if (type === REQUEST_TYPE_PROJECT) {

    dispatch({ type: REQUEST_PROJECTS_PENDING });
    fetch(apiDomain() + "articles/Article")
      .then(response => response.json())
      .then(posts => dispatch({ type: REQUEST_PROJECTS_SUCCESS, payload: posts.data }))
      .catch(error => dispatch({ type: REQUEST_PROJECTS_FAILED, payload: error }));
  }
}

// We pass the dispatch so that we have access to it!
// We want this to return a function! so we'll make it higher order
export const requestArticle = (path) => (dispatch) => {
  dispatch({ type: REQUEST_ARTICLE_PENDING });
  fetch(apiDomain() + "article/" + path)
    .then(response => response.json())
    .then(article => dispatch({ type: REQUEST_ARTICLE_SUCCESS, payload: article.data }))
    .catch(error => dispatch({ type: REQUEST_ARTICLE_FAILED, payload: error }));
}

// We pass the dispatch so that we have access to it!
// We want this to return a function! so we'll make it higher order
export const sendEmail = (formData) => (dispatch) => {
  dispatch({ type: SEND_EMAIL_PENDING });
  fetch(apiDomain() + "email", {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message
      })
    })
    .then(response => response.json())
    .then(returnValue => dispatch({ type: SEND_EMAIL_SUCCESS, payload: returnValue.data }))
    .catch(error => dispatch({ type: SEND_EMAIL_FAILED, payload: error }));
}