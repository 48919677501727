import React from "react";
import SocialButtons from "../SocialButtons/SocialButtons";

class CardFriend extends React.Component {
  render() {
    const {
      Name,
      description,
      color,
      imagelink,
      webImagelink,
      socialLinks,
    } = this.props;
    const images = require.context("../../images", true);
    let img = imagelink ? images("./" + imagelink) : webImagelink;
    return (
      <div
        className={"flex mb4 br2 aos-animate w-100 shadow-3 "}
        data-aos="fade-up"
      >
        <div className="dynamic-flex-block">
          <div className="ma2">
            <img
              src={img}
              alt={Name}
              className={"br-50 db center square-200 border-8 " + color}
            />
          </div>
          <div className="ma2">
            <h4>{Name}</h4>
            <SocialButtons socialLinks={socialLinks} justifyClass="tl" />
            <div className="">{description}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardFriend;
